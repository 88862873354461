document.addEventListener('DOMContentLoaded', () => {
// !-----------------------------------------------------------------!
    // Открытие менюшки
    function showMenu() {
        const btn = document.querySelector(".burger_js"),
            menu = document.querySelector(".header_links_js"),
            links = menu.querySelectorAll("a");

        btn.addEventListener("click", () => {
            menu.classList.toggle("-active")
        });
        links.forEach(elem => {
            elem.addEventListener("click", () => {
                menu.classList.remove("-active")
            })
        })

    }

    showMenu();

// !-----------------------------------------------------------------!

//    ДРЕНАЖНЫЕ СИСТЕМЫ ПОКАЗАТЬ БОЛЬШЕ
//    Функция чтобы показать доп инфу
    function drenazhSystemShowDopInfo() {
        let btn = document.querySelectorAll(".drenazh_elem_show_btn"),
            info = document.querySelectorAll(".drenazh_system_show_item_js");
        if (btn.length === 0 || info.length === 0) {
            return
        }
        // -active
        //    Скроем все доп поля
        btn.forEach((elem, i) => {
            elem.addEventListener("click", () => {
                info.forEach((hide, ih) => {
                    if (i !== ih) {
                        hide.classList.remove("-active")
                    }
                });
                info[i].classList.toggle("-active");
            })
        })

    }

    drenazhSystemShowDopInfo();

// !-----------------------------------------------------------------!


//    Слайдер фотоотчета

    try {
        const photoOtchetSlider = document.querySelectorAll(".photo_otchet_slider_js");
        photoOtchetSlider.forEach(elem => {
            new Carousel(elem, {
                Dots: false
            });
        })
    } catch (e) {
    }

//    СЛАЙДЕР ДЛЯ СТРАНИЦЫ ТОВАРА
    try {
        const tp_slider1 = new Carousel(document.querySelector(".tp_slider1"), {
            Dots: false
        });
        const tp_slider2 = new Carousel(document.querySelector(".tp_slider2"), {
            Sync: {
                target: tp_slider1,
            },
            Dots: false,
            Navigation: false,
            infinite: false,
            center: true,
            slidesPerPage: 1,
        });
    } catch (e) {
    }
//    Выключить автофокус у фенсибокс
    Fancybox.bind("[data-fancybox]", {
        autoFocus: false
    });

// !-----------------------------------------------------------------!

//    QUIZ

    class Quiz {
        _quiz;
        _quizWrap;
        _radInp;
        _btnBack;
        _iKey;
        _area;
        _btnArea;

        constructor(quizID) {
            this._quiz = document.getElementById(quizID);
            if (this._quiz === null) {
                return
            }
            this._quizWrap = this._quiz.querySelectorAll(".quiz_wrap_js");
            this._area = this._quiz.querySelector(".area_js");
            this._btnArea = this._quiz.querySelector(".area_js_btn");
            this._radInp = this._quiz.querySelectorAll("input[type=radio]");
            this._btnBack = this._quiz.querySelectorAll(".quiz_back_js");
            this._iKey = 0;

            this.addListener();
            this.addListenerToBtnBack();
        }

        addListener() {
            this._radInp.forEach(elem => {
                elem.addEventListener("click", (e) => {
                    console.log(e.target)
                    this.next();
                })
            });
            this.check_correct();
        }

        check_correct() {
            this._btnArea.addEventListener("click", () => {
                let area_error = this._quiz.querySelector(".area_error");
                if (this._area.value.trim() === '' || this._area.value.trim() === ' ' || parseInt(this._area.value.trim()) <= 0) {
                    area_error.style.display = "block";
                } else {
                    this.next()
                }
            })
        }

        addListenerToBtnBack() {
            this._btnBack.forEach(elem => {
                elem.addEventListener("click", () => {
                    this.prev();
                })
            });
        }

        next() {
            this._quizWrap[this._iKey].style.display = "none";
            this._quizWrap[this._iKey + 1].style.display = "block";
            this._iKey++;
        }

        prev() {
            this._quizWrap[this._iKey].style.display = "none";
            this._quizWrap[this._iKey - 1].style.display = "block";
            this._iKey--;
            this._radInp.forEach(elem => {
                if (elem.dataset.name === `quiz_rad_${this._iKey}`) {
                    elem.checked = false;
                }
            });
        }
    }

    let quiz1 = new Quiz("quiz-1");

// !-----------------------------------------------------------------!

//    QUIZ_CALC
    class QuizCalc extends Quiz {
        _width; // a
        _height; // b
        _inpPrice; // Показывает цену в конце калькулятора
        constructor(quizID) {
            super(quizID);
            if (this._quiz === null) {
                return
            }
            this._width = this._quiz.querySelector("[data-name='width']");
            this._height = this._quiz.querySelector("[data-name='height']");
            this._inpPrice = this._quiz.querySelector("[data-name='calc_itog']")
            this.check_correct();
        }

        check_correct() {
            this._btnArea.addEventListener("click", () => {
                let area_error = this._quiz.querySelector(".area_error");
                if (this._width.value.trim() === '' ||
                    this._width.value.trim() === ' ' ||
                    parseInt(this._width.value.trim()) <= 0 ||
                    this._height.value.trim() === '' ||
                    this._height.value.trim() === ' ' ||
                    parseInt(this._height.value.trim()) <= 0) {
                    area_error.style.display = "block";
                } else {
                    this.next()
                }
            })
        }

        calc() {
            let material, ogolovki = 0, obvyazka = 0;
            let a, b, sum, perimeter, calc_quantity, calc_total;

            console.log(this._width.value, this._height.value)
            this._radInp.forEach(elem => {
                if (elem.checked) { // если инпут выбран
                    if (elem.dataset.price !== "null") { // и имеет цену
                        // Подпишем для каждого элемента цену
                        switch (elem.dataset.pricetype) {
                            case "material":
                                material = parseInt(elem.dataset.price);
                                break;
                            case "ogolovki":
                                ogolovki = parseInt(elem.dataset.price);
                                break;
                            case "obvyazka":
                                obvyazka = parseInt(elem.dataset.price);
                                break
                        }
                    }
                }
            });
            a = this._width.value <= 3 ? 4 : this._width.value;
            a = Math.ceil(a);
            a = a % 2 === 1 ? a + 1 : a;
            b = this._height.value <= 3 ? 4 : this._height.value;
            b = Math.ceil(b);
            b = b % 2 === 1 ? b + 1 : b;

            perimeter = (a * 2) + (b * 2);

            a = this._width.value > 3 && this._width.value < 6 ? 6 : a;
            b = this._height.value > 3 && this._height.value < 6 ? 6 : b;


            calc_quantity = (a / 2) * (b / 2); // количество свай
            calc_total = calc_quantity * (material + 1000 + ogolovki) + obvyazka * perimeter; // итоговая цена

            this._inpPrice.value = "Итоговая сумма: " + calc_total + " рублей"; // покажем цену юзеру

        }

        next() {
            this._quizWrap[this._iKey].style.display = "none";
            this._quizWrap[this._iKey + 1].style.display = "block";
            this._iKey++;

            if (this._iKey === this._quizWrap.length - 1) { // Если последний экран
                this.calc(); // вызываем подсчет цен
            }
        }

    }

    let quiz_calc = new QuizCalc("quiz_calc-1");


// !-----------------------------------------------------------------!
//    ПОКАЗАТЬ ДОП ЭЛЕМЕНТЫ
//     Логика для всех блоков данного типа

    function showMoreLogic(countVisible, classElements, classBtn, classParent) {
        let elements, btn, parent, parentHeight = 0;


        try {
            parent = document.querySelector(`.${classParent}`);
            elements = document.querySelectorAll(`.${classElements}`);
            btn = document.querySelector(`.${classBtn}`);

        } catch (e) {
            console.log("Функция showMoreLogic. Нет элементов на странице. " + e);
            return
        }

        if (parent === null || elements === null || btn === null) {
            return
        }

        // скрыть лишние элементы
        elements.forEach((elem, i) => {
            if (i >= parseInt(countVisible)) {
                elem.classList.add('-hide')
            }
        });

        // Показать элементы при клике
        btn.addEventListener("click", () => {
            console.log("e");
            parentHeight = parent.getBoundingClientRect().height;
            // Меняем текст на кнопке
            if (elements[countVisible].classList.contains("-hide")) {
                btn.textContent = "Скрыть"
            } else {
                btn.textContent = "Показать все ↓";
                // чтобы экран пользователь оставался на том же месте где и был
                parentHeight = parent.getBoundingClientRect().height - parentHeight;
                window.scrollBy(0, -parentHeight)
            }
            // Скрываем элементы
            elements.forEach((elem, i) => {
                if (i >= parseInt(countVisible)) {
                    elem.classList.toggle('-hide')
                }
            });
        })
    }

    //
    // showMoreLogic(6, "filter_content_elem_js", "filter_btn_js", "filter_content_js");


    //Логика для табов и таблиц

    function myTabs(classWrapElements, classBtn, classContent) {
        // classWrapElements - обертка (родитель в котором находятся активные элементы) при нажатии будет срабатывать открытие контента
        // classBtn - кнопка анимации (будет галочка вращаться)
        // classContent - сам контент который будет открываться
        let elements = document.querySelectorAll(`.${classWrapElements}`);
        if (elements === null) {
            return
        }
        elements.forEach(elem => {
            let btn = elem.querySelector(`.${classBtn}`),
                content = elem.querySelector(`.${classContent}`);

            elem.addEventListener("click", function () {
                btn.classList.toggle("-active");
                content.classList.toggle("-hide");

                // Доп логика для блока вопросы и ответы (жесткая привязка к классу)
                if (classWrapElements === "question_elem_js") {
                    elem.classList.toggle("-no_height")
                }
            })

        })
    }

    myTabs("price_table_js", "price_table_drop_js", "price_table_content_js");
    myTabs("question_elem_js", "question_btn_js", "question_content_js");


// !-----------------------------------------------------------------!

    // РАЗВЕРНУТЬ ТЕКСТ В БЛОКЕ МАТЕРИАЛЫ
    function materialShow() {
        let elements;
        try {
            elements = document.querySelectorAll(".material_show_js");
        } catch (e) {
            console.log("Ошибка в materialShow. Нет этих элементов" + e);
            return
        }
        if (elements === null) {
            return
        }
        elements.forEach(elem => {
            try {
                let btn = elem.querySelector(".material_showBtn_js"),
                    btnHide = elem.querySelector(".material_hideBtn_js"),
                    hideContent = elem.querySelector(".material_hideContent_js");

                btn.addEventListener("click", () => {
                    btn.classList.toggle("-hide");
                    btnHide.classList.toggle("-hide");
                    hideContent.classList.toggle("-hide")
                });
                btnHide.addEventListener("click", () => {
                    btn.classList.toggle("-hide");
                    btnHide.classList.toggle("-hide");
                    hideContent.classList.toggle("-hide")
                })
            } catch (e) {
            }
        })
    }

    materialShow();


    // КАЛЬКУЛЯТОР
    /*class Calc {
        _calc;
        _data;
        _textBottom; // Текст над ценой
        _price;
        _dataInputs = []; // для хранения инпутов
        _insertInto; // Куда будем вставлять элемент
        _hiddenPrice; // скрытый элемент для того чтобы видеть в заявке цену

        constructor(insertInto, textBottom, data) {
            this._data = data.reverse();
            this._textBottom = textBottom;
            this._insertInto = insertInto;
            this.createCALC();

        }

        createCALC() {
            try {
                this._calc = document.querySelector(".calcTemplate").cloneNode(true);
            } catch (e) {
                return
            }
            // Скрытая цена, для того чтобы видеть в заявке
            this._hiddenPrice = this._calc.querySelector(".calc_price_js");
            // ПОСЛЕ какого элемента будет вставлять калькулятор
            let insert = document.querySelector(`.${this._insertInto}`);

            // Удаляю стиль, который скрывает калькулятор - шаблон
            this._calc.classList.remove("-hide");
            this._calc.classList.remove("calcTemplate");

            // Установил текст над ценой для каждого калькулятора
            this._calc.querySelector(".itog_title_js").textContent = this._textBottom;
            // Выявил HTML элемент в общую переменную класса
            this._price = this._calc.querySelector(".itog_price_js");

            // Перейдусь по всем данным которые дал в конструкторе, и на каждый объект буду создавать новый элемент калькулятора
            this._data.forEach((dataForOne, i) => {
                // Создаю дубликаты элементов при каждой итерации цикла, чтобы потом в него вставлять данные
                let elem = this._calc.querySelector(".calc_elem_js").cloneNode(true);

                // Удаляю элемент который был в шаблоне
                if (i === 0) {
                    this._calc.querySelector(".calc_elem_js").remove();
                }

                // Внутри клона, меняю нужные мне элементы
                let inp = elem.querySelector("input[type=range]"),
                    name = elem.querySelector(".calc_elem_name_js"),
                    subName = elem.querySelector(".calc_elem_subname_js"),
                    rangeValue = elem.querySelector(".range_value_js"),
                    rangeMin = elem.querySelector(".range_min_js"),
                    rangeMax = elem.querySelector(".range_max_js");


                // Поменял значения у элементов
                name.textContent = dataForOne.name; // Название слева
                subName.textContent = dataForOne.subName; // Что сделать слева мелкок
                rangeValue.textContent = dataForOne.min; // Текущее значение инпута
                rangeMin.textContent = dataForOne.min; // Минимальное значение инпута (тег "р"
                rangeMax.textContent = dataForOne.max; // Максимальное значение инпута (тег "р"

                // Изначально установил значения для инпутов
                inp.name = dataForOne.name;
                inp.min = dataForOne.min;
                inp.max = dataForOne.max;
                inp.step = dataForOne.step;
                inp.value = dataForOne.min;


                // Установил дата атрибут с ценой
                inp.dataset.price = dataForOne.price;
                // Установил дата атрибут, входит ли цена в стоимость или нет
                inp.dataset.start = dataForOne.startOtchet;
                // Добавил инпуты в массив, для дальнейшей работы с ними
                this._dataInputs.push(inp);


                // вставка каждого элемента внутрь калькулятора
                this._calc.prepend(elem);

                function changeValueAndPos() {
                    //Показываем сколько сейчас выбрано
                    rangeValue.textContent = inp.value;
                    // подвину табличку туда, где сейчас стоит инпут
                    rangeValue.style.left = `${100 / (inp.max - inp.min) * inp.value - (100 / (inp.max - inp.min) * inp.min)}%`
                }


                // Добавить слушателя на инпут, чтобы показывать текущее значение
                inp.addEventListener("click", changeValueAndPos);
                inp.addEventListener("mousemove", changeValueAndPos);
                inp.addEventListener("touchmove", changeValueAndPos);
                inp.addEventListener("touchend", changeValueAndPos);

            });
            // Добавить ВЕСЬ калькулятор на страницу
            insert.append(this._calc);

            this.raschet()

        }

        raschet() {
            let summ = 0, itog;
            let minusThis = 0;
            // Расчеты
            this._dataInputs.forEach(elem => {
                elem.addEventListener("change", () => {
                    summ = 0;
                    itog = calc() + " рублей";
                    this._price.textContent = itog;
                    this._hiddenPrice.value = itog;
                });
            });

            let calc = () => {
                this._dataInputs.forEach(elem => {
                    if (elem.dataset.start !== "null") {
                        // Для того чтобы убрать цену, которая уже включена в стоимость
                        minusThis = parseInt(elem.dataset.start) * parseInt(elem.dataset.price);
                        summ += Math.round(parseInt(elem.dataset.price) * elem.value - minusThis);
                    } else {
                        summ += parseInt(elem.dataset.price) * elem.value;
                    }

                });
                return summ;
            };
            this._price.textContent = calc() + " рублей";
            this._hiddenPrice.value = calc() + " рублей";
        }


    }*/
    /*
    * {
            name: "Количество соток", - наименование слева
            subName: "выберите площадь участка в сотках", ПОД наименованием слева
            min: 6, - минимальный показатель
            max: 50, - максиамльный показатель
            step: 1, - шаг изменения инпута
            price: 12300, - цена за 1 деление
            startOtchet: null - для того, чтобы включить в общую стоимость
            какую либо услугу (например с глубиной, во 2 и 3 калькуляторе)
        },
    */


// !-----------------------------------------------------------------!
    let data_filter = document.querySelector('.psevdo_filter_js'); // блок с вариантами ответов в "фильтре"
    let desc_filter = []; // массив для пояснения данных в "фильтре"
    data_filter.querySelectorAll('.filter_select_js > a').forEach(elem => {
        desc_filter.push(elem.textContent)
    });

//    Отправка на сервер
    //Поиск моих форм по странице
    function findMyForms() {
        let forms = document.querySelectorAll(".my-forms");
        forms.forEach(form => {
            // Для проверки данных в переменно $_POST
            // form.method = "POST";
            // form.action = "/fn";
            form.addEventListener("submit", (e) => {
                e.preventDefault();
                form.querySelector("button").disabled = true; // отключил кнопку
                form.querySelector("button").style.opacity = "0.6";
                req(e, form);
            });
        });
    }

    findMyForms();

    //Получаем ответ от сервера
    function req(e, actionElem) {
        let formData = new FormData(actionElem);
        //Проверка ЕСТЬ ЛИ ФАЙЛ
        if (formData.has("userfile")) {
            // проверка пустой ли файл
            if (formData.get("userfile").name !== "" || formData.get("userfile").type !== "application/octet-stream") {
                //Проверка на тип файла. Проходит по перечню и выдает false если найдет совпадение
                if (formData.get("userfile").type !== "application/vnd.openxmlformats-officedocument.wordprocessingml.document" &&
                    formData.get("userfile").type !== "application/msword" &&
                    formData.get("userfile").type !== "application/pdf" &&
                    formData.get("userfile").type !== "image/jpeg" &&
                    formData.get("userfile").type !== "image/png") {
                    return showPopup("format");
                    //Проверка на размер файла (до 10 Мб)10485760
                } else if (formData.get("userfile").size > 10485760) {
                    return showPopup("bigsize");
                }
                //Если файла не загрузили то удаляем это поле
            } else {
                formData.delete("userfile");
            }
        }
        //Проверка МАСКИ ТЕЛЕФОНА +7(xxx) ......
        if (
            formData.get("phone").length !== 17 ||
            formData.get("phone")[0] !== "+" ||
            formData.get("phone")[1] !== "7" ||
            formData.get("phone")[2] !== "(" ||
            formData.get('phone')[3] === '8' ||
            formData.get('phone')[6] !== ')' ||
            formData.get('phone')[16] === '_'
        ) {
            return showPopup("wrong", actionElem);
        }

//    ДОБАВИТЬ ДАННЫЕ ИЗ ПСЕВДОФИЛЬТРА В ФОРМУ
        if (actionElem.classList.contains("psevdo_form_js")) {
            let data_link = data_filter.querySelectorAll('.filter_select_js > a');
            data_link.forEach((elem, i) => {
                formData.append(desc_filter[i], elem.textContent)
            })
        }

        // formData.delete("politica");
        getData("/fn", formData)
            .then(data => showPopup(data, actionElem));
    }

    // Запрос к серверу
    async function getData(url, data) {
        const res = await fetch(`${url}`, {
            method: "POST",
            body: data
        });
        if (!res.ok) {
            console.log("Error: " + res.status);
        }
        return await res.text();
    }

    //Всплывашка с подтверждением / отказом
    function showPopup(data, form) {
        let div = document.createElement("div"),
            p = document.createElement("p"),
            span = document.createElement("span"),
            closeBtn = document.createElement("b");
        //close btn
        closeBtn.addEventListener("click", () => {
            div.remove();
        });
        //Check server - response
        if (data === "success") {
            // p.innerHTML = "Спасибо за заявку!";
            // span.innerHTML = "Мы свяжемся с вами в самое ближайшее время";
            // div.classList.remove("my-popup-error");
            // div.classList.add("my-popup-done");
            // //  Отчистим форму
            // clearMyForm(form);
            document.location.href = "/thanks";
            return;
        } else if (data === "wrong") {
            p.innerHTML = "Неверно ввели номер";
            span.innerHTML = "Пожалуйста, проверьте верно ли вы ввели номер. Номер должен начинаться с +7(ххх) и иметь длинну 11 цифр";
            div.classList.remove("my-popup-done");
            div.classList.add("my-popup-error");
            form.querySelector("button").disabled = false;
            form.querySelector("button").style.opacity = "1";
        } else if (data === "bigsize") {
            p.innerHTML = "Ошибка";
            span.innerHTML = "Простите, но размер вашего файла не должен быть больше 10 Мб";
            div.classList.remove("my-popup-done");
            div.classList.add("my-popup-error");
        } else if (data === "format") {
            p.innerHTML = "Неверный формат";
            span.innerHTML = "Формат вашего файла должен быть: .doc, .docx, .pdf, .jpeg, .jpg, .png";
            div.classList.remove("my-popup-done");
            div.classList.add("my-popup-error");
            form.querySelector("button").disabled = false;
            form.querySelector("button").style.opacity = "1";
        } else {
            p.innerHTML = "Что то пошло не так";
            span.innerHTML = "Пожалуйста, повторите еще, если ошибка не исчезнет дайте нам знать";
            div.classList.add("my-popup-error");
            form.querySelector("button").disabled = false;
            form.querySelector("button").style.opacity = "1";
        }
        //general things for pop-up
        div.classList.add("show-my-wrapper");
        closeBtn.append(document.createElement("i"));
        div.append(p, span, closeBtn);
        document.body.appendChild(div);
    }

    //    Анимация скрола
    function animate({timing, draw, duration}) {
        //Получили время начало анимации (не меняется)
        let start = performance.now();
        requestAnimationFrame(function animate(time) {
            /* time - время которое идет с начала анимации до ее конца (меняется)
             *  То есть начала будет = start*/

            // timeFraction изменяется от 0 до 1
            let timeFraction = (time - start) / duration;
            if (timeFraction > 1) timeFraction = 1;
            // вычисление текущего состояния анимации
            let progress = timing(timeFraction);

            draw(progress); // отрисовать её

            if (timeFraction < 1) {
                requestAnimationFrame(animate);
            }
        });
    }


    // Добавить маски на телефоны
    function addMask() {
        let phones = document.querySelectorAll('input[name=phone]');
        let options = {
            mask: '+{7}(000) 000 00 00',
            lazy: false
        };
        phones.forEach(elem => {
            IMask(elem, options)
        })
    }

    addMask();

    // Ленивая загрузка изображений ДЛЯ ИЗОБРАЖЕНИЙ КОТОРЫЕ НЕ НАХОДЯТСЯ В СЛАЙДЕРЕ
    // Пишем class="lazy" + тег data-src="ссылка" для этих изображений, а src=""
    let lazyloadImages;

    if ("IntersectionObserver" in window) {
        lazyloadImages = document.querySelectorAll(".lazy");
        let imageObserver = new IntersectionObserver(function (entries, observer) {
            entries.forEach(function (entry) {
                if (entry.isIntersecting) {
                    var image = entry.target;
                    image.src = image.dataset.src;
                    image.classList.remove("lazy");
                    imageObserver.unobserve(image);
                }
            });
        });

        lazyloadImages.forEach(function (image) {
            imageObserver.observe(image);
        });
    } else {
        let lazyloadThrottleTimeout;
        lazyloadImages = document.querySelectorAll(".lazy");

        function lazyload() {
            if (lazyloadThrottleTimeout) {
                clearTimeout(lazyloadThrottleTimeout);
            }

            lazyloadThrottleTimeout = setTimeout(function () {
                let scrollTop = window.pageYOffset;
                lazyloadImages.forEach(function (img) {
                    if (img.offsetTop < (window.innerHeight + scrollTop)) {
                        img.src = img.dataset.src;
                        img.classList.remove('lazy');
                    }
                });
                if (lazyloadImages.length === 0) {
                    document.removeEventListener("scroll", lazyload);
                    window.removeEventListener("resize", lazyload);
                    window.removeEventListener("orientationChange", lazyload);
                }
            }, 20);
        }

        document.addEventListener("scroll", lazyload);
        window.addEventListener("resize", lazyload);
        window.addEventListener("orientationChange", lazyload);
    }

// -------------- preventDefault --------------------
    function removeLink() {
        let a_arr = document.querySelectorAll(".remove_link");
        a_arr.forEach(elem => {
            elem.addEventListener("click", (e) => {
                e.preventDefault();
            })
        })
    }

    removeLink();
// -------------- preventDefault END --------------------


// -------------- FILTER --------------------

// Переключение списка фильтра
    function filterSelect() {
        let selectElements = document.querySelectorAll(".filter_select_js"),
            lists = document.querySelectorAll(".filter_list_js");

        // Показать список фильтрации при клике
        selectElements.forEach((elem, i) => {
            elem.addEventListener("click", function (e) {
                e.preventDefault();
                //Показали список
                if (e.target.closest(".-list") === null) {
                    lists[i].classList.toggle("-hide")
                }
            })
        });
        lists.forEach((elem, i) => {
            let listElements = elem.querySelectorAll('a');// Все элементы внутри списка
            listElements.forEach(listElem => {
                listElem.addEventListener("click", function (e) {
                    e.preventDefault();

                    // Удалить жирность у всех элементов
                    listElements.forEach(removeBold => {
                        removeBold.style.fontWeight = "normal";
                    });

                    // Удалим элемента из оглавления фильтра и поместим туда выбранный элемент
                    selectElements[i].querySelector("a").remove();
                    selectElements[i].append(this.cloneNode(true));
                    this.style.fontWeight = "bold";
                    // Скрою список
                    elem.classList.toggle("-hide")
                })
            })
        })
    }

    filterSelect();

// Фильтрация элементов
    function filterElements() {
        let button = document.querySelector(".filter_btn_js"),
            filterWrapper = document.querySelector(".filter_content_js"),
            filterElements = document.querySelectorAll(".filter_content_elem_js");

        let newListElements = Array.from(filterElements); // Список отфильтрованных элементов
        let filterSelection; // теги а по которым происходит фильтрация

        // Кнопка показа элементов (по 6 шт, "показать еще")
        let filterShowMoreBtn = document.querySelector(".filter_btn_showmore_js");

        // Активация фильтрования
        button.addEventListener("click", () => {
            filterSelection = document.querySelectorAll(".filter_select_js > a");
            // Данные из фильтров
            let name = filterSelection[0], // поиск по имени
                sep_price = filterSelection[1], // по цене септика
                mont_price = filterSelection[2]; // по цене монтажа

            newListElements = Array.from(filterElements);

            // ПОИСК ПО ИМЕНИ - ДА
            if (name.dataset.def !== "true") {
                newListElements = newListElements.filter(elem => {
                    // Взяли данные из элементов, по которым нужно фильтровать
                    let e_name = elem.querySelector('.filter_elem_name_js').textContent.toLowerCase();
                    if ((e_name.indexOf(name.textContent.toLowerCase(), 0) !== -1)) {
                        return true
                    }
                })
            }
            // ПОИСК ПО ЦЕНЕ - ДА
            if (sep_price.dataset.def !== "true") {
                newListElements = newListElements.filter(elem => {
                    let e_sepPrice = parseInt(elem.querySelector('.f_septik_price_js').textContent);

                    switch (sep_price.dataset.oborud) {
                        case "<30":
                            if (e_sepPrice < 30000) {
                                return true
                            }
                            break;
                        case "30-60":
                            if (e_sepPrice >= 30000 && e_sepPrice <= 60000) {
                                return true
                            }
                            break;
                        case "60-100":
                            if (e_sepPrice >= 60000 && e_sepPrice <= 100000) {
                                return true
                            }
                            break;
                        case "100-200":
                            if (e_sepPrice >= 100000 && e_sepPrice <= 200000) {
                                return true
                            }
                            break;
                        case "200-500":
                            if (e_sepPrice >= 200000 && e_sepPrice <= 500000) {
                                return true
                            }
                            break;
                        case "500>":
                            if (e_sepPrice > 500000) {
                                return true
                            }
                            break;
                    }
                })

            }
            // ПОИСК ПО МОНТАЖУ - ДА
            if (mont_price.dataset.def !== "true") {
                newListElements = newListElements.filter(elem => {
                    let e_montPrice = parseInt(elem.querySelector('.f_montaj_price_js').textContent);
                    switch (mont_price.dataset.montaj) {
                        case "<30":
                            if (e_montPrice < 30000) {
                                return true
                            }
                            break;
                        case "30-50":
                            if (e_montPrice >= 30000 && e_montPrice <= 50000) {
                                return true
                            }
                            break;
                        case "50-100":
                            if (e_montPrice >= 50000 && e_montPrice <= 100000) {
                                return true
                            }
                            break;
                        case "100>":
                            if (e_montPrice > 100000) {
                                return true
                            }
                            break;
                    }
                })
            }

            // Если новых элементов <= 6 шт, то скроем кнопку "Показать еще"
            newListElements.length <= 6
                ? filterShowMoreBtn.style.display = "none"
                :filterShowMoreBtn.style.display = "block";

            render(newListElements)

        });


        let i_key = 6; // переменная для отображения элементов по 6 шт

        // Отрисовка новых элементов согласно фильтру
        function render(elements) {
            // Удалить все элементы
            while (filterWrapper.firstChild) {
                filterWrapper.removeChild(filterWrapper.firstChild);
            }
            // Если нет элементов которые подходят условию фильтра
            if (elements.length === 0) {
                filterWrapper.append(document.createElement("p")
                    .textContent = "Товаров согласно вашему запросу нет")
            } else {
                // Заново отрисовать
                elements.forEach((elem, i) => {
                    if (i < 6) {
                        elem.classList.remove("-hide");
                        filterWrapper.append(elem)
                    } else {
                        elem.classList.add("-hide");
                        filterWrapper.append(elem)
                    }
                });
                i_key = 6;
            }
        }

        //  Показывать по 6 элементов фильтра
        function filterShowMore() {
            // Высота род. блок чтобы сделать скролл вверх к началу фильтра
            let parentHeight = filterWrapper.getBoundingClientRect().height;

            filterShowMoreBtn.addEventListener("click", () => {
                // Если есть элементы, которые надо показывать
                if (newListElements.length > i_key) {
                    // то покажем следующие 6 элементов
                    for (let i = 0; i < 6; i++) {
                        if (newListElements[i_key + i] !== undefined) {
                            newListElements[i_key + i].classList.remove("-hide")
                        } else {
                            break
                        }
                    }
                    i_key += 6;
                    // Поменяем текст на кнопке, когда все элементы кончились
                    if (newListElements.length <= i_key) {
                        filterShowMoreBtn.textContent = "Скрыть"
                    }
                } else {
                    // Скрол обратно к началу фильтра
                    parentHeight = filterWrapper.getBoundingClientRect().height;
                    // Скрыть все элементы, кроме 1-6
                    for (let i = 6; i < newListElements.length; i++) {
                        newListElements[i].classList.add("-hide")
                    }
                    window.scrollBy(0, -parentHeight);
                    filterShowMoreBtn.textContent = "Показать еще";
                    i_key = 6
                }

            })
        }

        filterShowMore();
    }

    filterElements()


// -------------- FILTER END --------------------


});












